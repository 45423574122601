<template>
  <a-checkbox v-bind="$attrs" :checked="newChecked" @change="changeValue">
    <slot></slot>
  </a-checkbox>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      default: false,
    },
    checkedVal: {
      default: true,
    },
    unCheckedVal: {
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    newChecked() {
      return this.checked === this.checkedVal;
    },
  },
  methods: {
    changeValue(val) {
      this.$emit('change', val.target.checked ? this.checkedVal : this.unCheckedVal);
    },
  },
  mounted() {},
};
</script>
