<template>
  <a-select
    show-search
    v-model="inputValue"
    @change="handleChange"
    :default-active-first-option="false"
    :filter-option="false"
    :not-found-content="null"
    :getPopupContainer="getPopupContainer"
    placeholder="选择广告主"
    @search="handleSearch"
    @select="handleSelect"
    v-bind="$attrs"
    v-on="$listeners"
    :loading="loading"
  >
    <a-select-option
      :value="item.advertiserId"
      :label="item.advertiserName"
      v-for="item in advertiser"
      :key="item.advertiserId"
    >
      {{ item.advertiserName }}
    </a-select-option>
  </a-select>
</template>

<script>
import _ from 'lodash';
import api from '@/api/core/deliverManage.js';

export default {
  props: {
    value: {
      type: String,
      default: undefined,
    },
    defaultAdvertiserList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      inputValue: undefined,
      params: {
        advertiserName: '',
      },
      advertiser: [],
    };
  },
  watch: {
    value(val) {
      this.inputValue = val;
    },
    defaultAdvertiserList(val) {
      this.advertiser = val;
    },
  },
  methods: {
    getPopupContainer(triggerNode) {
      return triggerNode.parentNode || document.body;
    },
    /**
     * 获取广告主列表
     * @param {string} val 广告主名称
     */
    async getAdvertiserList(val) {
      try {
        if (!val) {
          this.advertiser = [];
          return;
        }
        this.loading = true;
        this.params.advertiserName = val;
        const { code, data, message } = await api.getAdvertiserList(this.params);
        this.loading = false;
        if (code === 200) {
          this.advertiser = data;
        } else {
          this.message.error(message);
        }
      } catch (error) {
        this.loading = false;
        console.log('error:', error);
      }
    },
    /**
     * 搜索广告主
     */
    handleSearch: _.debounce(async function (val) {
      await this.getAdvertiserList(val);
    }, 300),
    /**
     * 选择广告主
     * @param {*} _
     * @param {*} option
     * @return {*}
     */
    handleSelect(_, option) {
      this.$emit('selected', _, option);
    },
    handleChange(val) {
      this.$emit('input', val);
      this.$emit('change', val);
    },
  },
  created() {
    this.inputValue = this.value;
  },
};
</script>

<style scoped lang="scss"></style>
