<template>
  <a-card>
    <a-spin :spinning="isLoading">
      <a-form-model ref="form" :model="form" :rules="rules" v-bind="layout">
        <a-form-model-item label="广告主" prop="advertiserId">
          <AdvertiserSelect
            class="input"
            v-model="form.advertiserId"
            :defaultAdvertiserList="defaultAdvertiserList"
            placeholder="请输入"
          ></AdvertiserSelect>
        </a-form-model-item>
        <a-form-model-item label="目标统计周期" prop="endTime" ref="endTimeRef">
          <a-date-picker
            style="width: 38%"
            v-model="form.startTime"
            :disabled-date="disabledStartDate"
            :allow-clear="false"
          />
          <span style="display: inline-block; width: 4%; text-align: center">~</span>
          <a-date-picker
            style="width: 38%"
            v-model="form.endTime"
            :disabled-date="disabledEndDate"
            :allow-clear="false"
            @change="$refs['endTimeRef'].onFieldChange()"
          />
        </a-form-model-item>
        <a-form-model-item label="消耗目标" prop="costTarget">
          <a-input-number
            class="input"
            placeholder="请输入"
            :min="1"
            :max="9999999999"
            :precision="0"
            :step="1"
            v-model="form.costTarget"
          ></a-input-number
          ><span class="unit">元</span>
        </a-form-model-item>
        <a-form-model-item label="线索量目标">
          <a-input-number
            class="input"
            placeholder="请输入"
            :min="1"
            :max="9999999999"
            :precision="0"
            :step="1"
            v-model="form.clueTarget"
          ></a-input-number
          ><span class="unit">条</span>
        </a-form-model-item>
        <a-form-model-item label="线索单价目标">
          <a-input-number
            class="input"
            placeholder="请输入"
            :min="1"
            :max="9999999999"
            :precision="0"
            :step="1"
            v-model="form.clueUnitPriceTarget"
          ></a-input-number
          ><span class="unit">元/条</span>
        </a-form-model-item>
        <a-form-model-item label="私信开口人数目标">
          <a-input-number
            class="input"
            placeholder="请输入"
            :min="1"
            :max="9999999999"
            :precision="0"
            :step="1"
            v-model="form.msgOpenNumTarget"
          ></a-input-number
          ><span class="unit">人</span>
        </a-form-model-item>
        <a-form-model-item label="私信开口单价目标">
          <a-input-number
            class="input"
            placeholder="请输入"
            :min="1"
            :max="9999999999"
            :precision="0"
            :step="1"
            v-model="form.msgUnitPriceTarget"
          ></a-input-number
          ><span class="unit">元/条</span>
        </a-form-model-item>
        <a-form-model-item label="私信线索转化率目标">
          <a-input-number
            class="input"
            placeholder="请输入"
            :min="1"
            :max="9999999999"
            :precision="0"
            :step="1"
            v-model="form.msgClueConvertTarget"
          ></a-input-number
          ><span class="unit">%</span>
        </a-form-model-item>
        <a-form-model-item label="UBL消耗占比目标">
          <a-input-number
            class="input"
            placeholder="请输入"
            :min="1"
            :max="9999999999"
            :precision="0"
            :step="1"
            v-model="form.ublCostProportionTarget"
          ></a-input-number
          ><span class="unit">%</span>
        </a-form-model-item>
        <a-form-model-item :colon="false">
          <span slot="label" class="title">统计范围设置</span>
        </a-form-model-item>
        <a-row class="mb-10">
          <a-col :span="6" class="label">1、统计的线索类型：</a-col>
          <a-col :span="11">
            <any-checkbox :checkedVal="1" :unCheckedVal="0" v-model="form.clueMessageCount"> 私信留资 </any-checkbox>
            <any-checkbox :checkedVal="1" :unCheckedVal="0" v-model="form.formSubmitCount"> 表单提交数 </any-checkbox>
            <any-checkbox :checkedVal="1" :unCheckedVal="0" v-model="form.phoneConnect"> 电话接通数 </any-checkbox>
          </a-col>
        </a-row>
        <a-row class="mb-10">
          <a-col :span="6" class="label">2、统计的巨量项目类型</a-col>
        </a-row>
        <a-row class="mb-10">
          <a-col :span="6" class="label">营销场景类型：</a-col>
          <a-col :span="11">
            <any-checkbox :checkedVal="1" :unCheckedVal="0" v-model="form.videoPicture"> 短视频/图片 </any-checkbox>
            <any-checkbox :checkedVal="1" :unCheckedVal="0" v-model="form.live"> 直播 </any-checkbox>
          </a-col>
        </a-row>
        <a-row class="mb-10">
          <a-col :span="6" class="label"></a-col>
          <a-col :span="18"> PS：不勾选不统计对应类型的项目相关数据（消耗、私信开口、私信留资、表单提交数等） </a-col>
        </a-row>
        <a-row class="mb-10">
          <a-col :span="6" class="label">优化目标类型：</a-col>
          <a-col :span="18">
            <any-checkbox :checkedVal="1" :unCheckedVal="0" v-model="form.convertTypeMessageAction">
              私信消息
            </any-checkbox>
            <any-checkbox :checkedVal="1" :unCheckedVal="0" v-model="form.convertTypeMessageClue">
              私信留资
            </any-checkbox>
            <any-checkbox :checkedVal="1" :unCheckedVal="0" v-model="form.convertTypeForm"> 表单提交 </any-checkbox>
            <any-checkbox :checkedVal="1" :unCheckedVal="0" v-model="form.other"> 其他 </any-checkbox>
            <span>PS：全部选择代表不限优化目标</span>
          </a-col>
        </a-row>
        <a-row class="mb-10">
          <a-col :span="6" class="label"></a-col>
          <a-col :span="18"> PS：不勾选不统计对应类型的项目相关数据（消耗、私信开口、私信留资、表单提交数等） </a-col>
        </a-row>
        <a-form-model-item label=" " :colon="false">
          <a-space>
            <base-button :title="'取消'" @onClickBtn="handleBack"></base-button>
            <base-button
              :type="'primary'"
              title="保存"
              :loading="isSubmit"
              :disabled="isSubmit"
              @onClickBtn="handleSubmit"
            ></base-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-card>
</template>

<script>
import AdvertiserSelect from './components/AdvertiserSelect.vue';
import AnyCheckbox from '@/components/AnyCheckbox/AnyCheckbox.vue';
import moment from 'moment';

export default {
  components: { AdvertiserSelect, AnyCheckbox },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 11 },
      },
      defaultAdvertiserList: [],
      form: {
        id: undefined,
        advertiserId: undefined,
        startTime: moment(),
        endTime: undefined,
        costTarget: undefined,
        clueTarget: undefined,
        clueUnitPriceTarget: undefined,
        msgOpenNumTarget: undefined,
        msgUnitPriceTarget: undefined,
        msgClueConvertTarget: undefined,
        ublCostProportionTarget: undefined,
        clueMessageCount: 1,
        formSubmitCount: 1,
        phoneConnect: 1,
        videoPicture: 1,
        live: 1,
        convertTypeMessageAction: 1,
        convertTypeMessageClue: 1,
        convertTypeForm: 1,
        other: 1,
      },
      rules: {
        advertiserId: [
          {
            required: true,
            message: '请选择广告主',
            trigger: 'blur',
          },
        ],
        endTime: [
          {
            required: true,
            message: '请选择目标统计周期',
            trigger: 'change',
          },
        ],
        costTarget: [
          {
            required: true,
            message: '请输入消耗目标',
            trigger: 'blur',
          },
        ],
      },
      isLoading: false,
      isSubmit: false,
    };
  },
  methods: {
    disabledStartDate(current) {
      return current && current < moment('2023-01-01').startOf('day');
    },
    disabledEndDate(current) {
      return (
        current &&
        (current < moment(this.form.startTime).startOf('day') ||
          current > moment(this.form.startTime).add(399, 'days').endOf('day'))
      );
    },
    getDetail() {
      this.isLoading = true;
      this.$api.core.deliverManage
        .getAdTargetDetail(this.form.id)
        .then((res) => {
          if (res.code == 200) {
            Object.assign(this.form, res.data);
            this.defaultAdvertiserList = [
              {
                advertiserName: res.data.advertiserName,
                advertiserId: res.data.advertiserId,
              },
            ];
            this.form.startTime = moment(this.form.startTime);
            this.form.endTime = moment(this.form.endTime);
          } else {
            this.$message.error(`获取详情失败，${res.message}`);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //取消
    handleBack() {
      let that = this;
      this.$confirm({
        content: (h) => (
          <div>
            <span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
          </div>
        ),
        okText: '确定',
        cancelText: '取消',
        onOk() {
          that.$router.push({
            name: 'targetManage',
          });
        },
      });
    },
    //提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isSubmit = true;
          const params = JSON.parse(JSON.stringify(this.form));
          params.startTime = moment(params.startTime).format('YYYY-MM-DD 00:00:00');
          params.endTime = moment(params.endTime).format('YYYY-MM-DD 23:59:59');
          const saveApi = params.id
            ? this.$api.core.deliverManage.editAdTargetList
            : this.$api.core.deliverManage.addAdTargetList;
          saveApi(params)
            .then((res) => {
              this.isSubmit = false;
              if (res.code == 200) {
                this.$message.success(`操作成功`);
                this.$router.push({
                  name: 'targetManage',
                });
              } else {
                this.$message.error(`操作失败，${res.message}`);
              }
            })
            .catch(() => {
              this.isSubmit = false;
            });
        }
      });
    },
  },
  created() {
    this.form.id = this.$route.query.id;
    if (this.form.id) {
      this.getDetail();
    }
  },
};
</script>

<style scoped lang="scss">
.mb-10 {
  margin-bottom: 10px;
}
.input {
  width: 80%;
}
.unit {
  margin-left: 10px;
}

.title {
  font-weight: bold;
  font-size: 16px;
}
.label {
  color: rgba(0, 0, 0, 0.85);
  text-align: right;
}
</style>
